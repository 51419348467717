import Vue from "vue";
import Vuex from "vuex";
import {
  Howl,
  // Howler
} from 'howler';

let player;

// const tp = require("timeout-percentage/lib/index.js");

// function intervalCallback(percentage) {
//   if (!store.state.started) return
//   store.state.startPercent = percentage
// }

// let options = {
//   intervalCallback: intervalCallback, // Will be called at each interval
//   intervalEndCallback: top,
//   totalTimeout: 0,// Will be called at the end of the timeout
//   numberOfIntervals: 6 // Number of intervals
// }

// let createjs;

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    started: false,
    startPercent: 0,
    timeSpent: 0,
    secondsBeforeTop: null,
    player: null
  },
  getters: {},
  mutations: {
    falseStart(state) {
      state.timeSpent = 0
      state.started = false
      state.startPercent = 0
      player.play('false');
    }
  },
  actions: {
    loadSounds() {
      player = new Howl({
        src: ['./sounds.mp3', './sounds.webm'],
        sprite: {
          marks: [0, 727],
          ready: [1504, 360],
          top: [3010, 800],
          false: [5012, 1900]
        },
        html5: true
      })
      // createjs = window.createjs;
      // createjs.Sound.registerPlugins([createjs.WebAudioPlugin])
      // createjs.Sound.initializeDefaultPlugins();
      // createjs.WebAudioPlugin.playEmptySound();
      // createjs.Sound.alternateExtensions = ["mp3"];
      // const assetsPath = "./";
      // const sounds = [{
      //   src: "sounds.ogg",
      //   data: {
      //     audioSprite: [{
      //         id: "marks",
      //         startTime: 0,
      //         duration: 847
      //       },
      //       {
      //         id: "ready",
      //         startTime: 847,
      //         duration: 551
      //       },
      //       {
      //         id: "top",
      //         startTime: 1398,
      //         duration: 930
      //       },
      //       {
      //         id: "false",
      //         startTime: 2328,
      //         duration: 2000
      //       }
      //     ]
      //   }
      // }];
      // createjs.Sound.registerSounds(sounds, assetsPath);
    },
    start() {
      console.log('clicked')
      this.dispatch("loadSounds")
      this.state.timeSpent = 0;
      // Howler.autoUnlock = true;
      this.state.started = true;
      this.state.secondsBeforeTop = Math.random() * (3.5 - 1.5) + 1.5;
      setTimeout(()=>{
        this.dispatch("loop")
      }, 300)
    },
    loop() {
      if (this.state.started) {
        this.dispatch("checkSounds")
        this.state.timeSpent += 100;
        setTimeout(() => {
          this.dispatch("loop")
        }, 100);
      }
    },
    checkSounds() {
      switch (true) {
        case (this.state.timeSpent == 0):
          player.play('marks')
          // createjs.Sound.play("marks");
          break;
        case (this.state.timeSpent == 3800):
          player.play('ready')
          // createjs.Sound.play("ready");
          break;
        case (this.state.timeSpent >= 3800 + (this.state.secondsBeforeTop * 1000)):
          player.play('top');
          // createjs.Sound.play("top");
          this.state.started = false;
          this.state.timeSpent = 0;
          this.state.startPercent = 0;
          break;
      }
    }
  }
});

export default store;
