<template>
  <v-btn
    class="ma-2"
    outlined
    small
    color="red"
    :disabled="!this.$store.state.started"
    @click="falseStart"
  >
    Faux Départ
  </v-btn>
</template>

<script>
export default {
  data: function () {
    return {
      started: this.$store.state.started
    }
  },
  methods: {
    falseStart: function () {
      this.$store.commit('falseStart')
    }
  },
  name: 'FalseStartButton'
}
</script>
