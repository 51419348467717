<template>
  <v-app>
    <AppBar />
    <v-main>
      <div class="d-flex justify-space-around align-center flex-column" style="width: 100%; height: 100%;">
        <div></div>
        <StartButton/>
        <FalseStartButton/>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import StartButton from './components/StartButton';
import FalseStartButton from './components/FalseStartButton.vue'
import AppBar from './components/AppBar.vue'

export default {
  name: 'App',
  beforeMount() {
    // this.$store.dispatch("loadSounds")
  },
  components: {
    AppBar,
    StartButton,
    FalseStartButton
  },

  data: () => ({
    //
  }),
};
</script>
