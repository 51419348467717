<template>
<div>
  <v-progress-circular :value="this.$store.state.startPercent" :size="208" color="primary" >

    <v-btn
      fab
      color="primary"
      elevation="5"
      width="200px"
      height="200px"
      @click="start()"
      :disabled="this.$store.state.started"
    >
      <v-icon color="white"> mdi-timer </v-icon>
    </v-btn>
  </v-progress-circular>
</div>
</template>
<script>
export default {
  name: "StartButton",
  methods: {
    start: function () {
      this.$store.dispatch("start")
    }
  },
};
</script>

<style>
.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0!important;
}
</style>
