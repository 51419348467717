<template>
 <v-app-bar
    app
    color="primary"
    dark
  >
    <v-toolbar-title>Départs Speed</v-toolbar-title>
    <v-spacer></v-spacer>
    <span>v2.1</span>
  </v-app-bar>
</template>

<script>
export default {
  name: 'AppBar'
}
</script>
